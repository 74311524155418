import { TITLE } from '@/config'

const set_width = state => (state.width = window.innerWidth)

const set_loading = (state, loading) => (state.loading = loading)

const set_yuyan = (state, yuyan) => {
  state.yuyan = yuyan
  localStorage.setItem(TITLE + 'yuyan', JSON.stringify(yuyan))
}

const set_yuyans = (state, yuyans) => {
  state.yuyans = yuyans
  localStorage.setItem(TITLE + 'yuyans', JSON.stringify(yuyans))
}

const set_yuyanbao = (state, yuyanbao) => {
  state.yuyanbao = yuyanbao
  localStorage.setItem(TITLE + 'yuyanbao', JSON.stringify(yuyanbao))
}

const set_shezhi = (state, shezhi) => {
  state.shezhi = shezhi
  localStorage.setItem(TITLE + 'shezhi', JSON.stringify(shezhi))
}

const set_shezhi_mitu = (state, shezhi) => {
  state.shezhimitu = shezhi
  localStorage.setItem(TITLE + 'shezhimitu', JSON.stringify(shezhi))
}

const set_yonghu = (state, yonghu) => {
  state.login = 1
  state.yonghu = yonghu
  localStorage.setItem('login', 1)
  localStorage.setItem('yonghu', JSON.stringify(yonghu))
}
const logout = state => {
  state.login = 0
  state.yonghu = {}
  localStorage.setItem('login', 0)
  localStorage.removeItem('yonghu')
}

const gengxin_gouwuche = state => {
  state.cart.total = 0
  state.cart.count = 0
  for (let i = 0, len = state.cart.products.length; i < len; i++) {
    state.cart.count += state.cart.products[i].count
    state.cart.total += (state.cart.products[i].count * state.cart.products[i].sku[state.cart.products[i].jiageindex].jiage * (100 - state.cart.products[i].sku[state.cart.products[i].jiageindex].zhekou)) / 100
  }
  localStorage.setItem('cart', JSON.stringify(state.cart))
}

const jia_gouwuche = (state, cartItem) => {
  let index = -1
  for (let i = 0, len = state.cart.products.length; i < len; i += 1) {
    if (state.cart.products[i].id === cartItem.id && state.cart.products[i].jiageindex == cartItem.jiageindex) {
      index = i
      break
    }
  }
  if (index === -1) {
    state.cart.products.push(cartItem)
  } else {
    state.cart.products[index].count += cartItem.count
  }
  gengxin_gouwuche(state)
}

const clear_gouwuche = state => {
  state.cart.products = []
  localStorage.setItem('cart', JSON.stringify(state.cart))
  gengxin_gouwuche(state)
}

export default {
  set_loading,
  set_width,
  set_yonghu,
  logout,
  gengxin_gouwuche,
  clear_gouwuche,
  jia_gouwuche,
  set_yuyan,
  set_yuyans,
  set_yuyanbao,
  set_shezhi,
  set_shezhi_mitu,
}
