import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../Layout.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "yxl"*/ '@/views/index.vue'),
      },

      {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/Login.vue'),
      },
      {
        path: '/aboutus',
        name: 'AboutUs',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/AboutUs.vue'),
      },
      {
        path: '/contactus',
        name: 'ContactUs',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/ContactUs.vue'),
      },
      {
        path: '/produits',
        name: 'Produits',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/Produits.vue'),
      },
      {
        path: '/produit',
        name: 'Produit',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/Produit.vue'),
      },
      {
        path: '/cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/Cart.vue'),
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/Checkout.vue'),
      },
      {
        path: '/bonachat',
        name: 'BonAchat',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/BonAchat.vue'),
      },
      {
        path: '/boncadeau',
        name: 'BonCadeau',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/BonCadeau.vue'),
      },
      {
        path: '/account',
        name: 'WoDeZhangHu',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/WoDeZhangHu.vue'),
      },
      {
        path: '/mentionlegal',
        name: 'MentionLegal',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/MentionLegal.vue'),
      },
      {
        path: '/cgv',
        name: 'CGV',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/CGV.vue'),
      },
      {
        path: '/paiementsecurise',
        name: 'PaiementSecurise',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/PaiementSecurise.vue'),
      },
      {
        path: '/modelivraison',
        name: 'ModeLivraison',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/ModeLivraison.vue'),
      },
      {
        path: '/demanderetour',
        name: 'DemandeRetour',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/DemandeRetour.vue'),
      },
      {
        path: '/payer',
        name: 'Payer',
        component: () => import(/* webpackChunkName: "yxl" */ '@/views/Payer.vue'),
      },
    ],
  },
  {
    path: '/404',
    name: 'Page404',
    component: () => import(/* webpackChunkName: "yxl" */ '@/views/Page404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

router.beforeEach((to, from, next) => {
  const route = router.getRoutes()
  const token = localStorage.getItem('token')
  const tokenexp = localStorage.getItem('tokenexp')
  if (route.findIndex(i => i.name === to.name) === -1) {
    next('/404')
  } else if (!to.meta.login) {
    next()
  } else if (token && new Date().getTime() < parseInt(tokenexp)) {
    next()
  } else if (to.path === '/login') next()
  else {
    next(`/login?redirect=${to.path}`)
  }
})

router.afterEach(() => {
  Wolmart.call(Wolmart.init)
})

export default router
