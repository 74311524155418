import Vue from 'vue'
import Swal from 'sweetalert2'
import localforage from 'localforage'
import { TUPIANBASE, YUYAN, TITLE } from './config'
import { upload } from './api/upload'
import store from './store'

Vue.prototype.$tupianbase = TUPIANBASE
Vue.prototype.$tupianmoren = TUPIANBASE + 'system/moren.webp'

localforage.config({
  driver: localforage.INDEXEDDB,
  name: TITLE + '-yxl-db',
  version: 1,
  storeName: TITLE + '-yxl-store',
})

Vue.prototype.$localforage = localforage

Vue.prototype.console_yxl = data => console.log(data)

// 上传文件
Vue.prototype.upload = (id, callback = () => {}, fin = () => {}) => {
  const files = document.getElementById(id).files
  const formData = new FormData()
  for (let i = 0, len = files.length; i < len; i += 1) {
    formData.append('file', files[i])
  }
  upload(formData)
    .then(data => {
      if (data.status === 1) callback(data.data)
    })
    .finally(() => {
      document.getElementById(id).value = ''
      fin()
    })
}

Vue.prototype.toast_center_info = ({ icon = 'info', title = '', iconColor = '#012B7F', showConfirmButton = true, confirmButtonText = '<i class="fa fa-check"></i>' }) => {
  Swal.fire({
    icon,
    text: title,
    iconColor,
    showConfirmButton,
    confirmButtonText,
    confirmButtonColor: iconColor,
  }).then(() => '')
}

Vue.prototype.toast_center_success = ({ icon = 'success', title = '', iconColor = '#012B7F', showConfirmButton = false, confirmButtonText = '', timer = 1000 }) => {
  Swal.fire({
    icon,
    text: title,
    iconColor,
    showConfirmButton,
    confirmButtonText,
    timer,
  }).then(() => '')
}

// toast删除弹框提示 type: 1删除提示
Vue.prototype['toast_tishi'] = ({ data, type = 1, callback = () => {}, confirmButtonText, cancelButtonText }) => {
  const yuyan = localStorage.getItem(TITLE + 'yuyan') || YUYAN
  let title = ''
  if (type === 1) title = yuyan === 'cn' ? '您确定要删除吗?' : yuyan === 'fr' ? 'Es-tu sûr de vouloir le supprimer?' : 'Are you sure you want to delete it?'

  if (!confirmButtonText) confirmButtonText = yuyan === 'cn' ? '确定' : yuyan === 'fr' ? 'Oui' : 'Yes'
  if (!cancelButtonText) cancelButtonText = yuyan === 'cn' ? '取消' : yuyan === 'fr' ? 'Non' : 'No'
  Swal.fire({
    title,
    text: '',
    icon: 'error',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText,
    cancelButtonText,
  }).then(result => {
    if (result.isConfirmed) callback(data)
  })
}

// 返回年月日 type = 1代表年月时分秒
Vue.prototype['format_date'] = (date, type) => {
  let d = new Date(date)
  const YY = d.getFullYear()
  const MM = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  const DD = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  const HH = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
  const mm = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
  const SS = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
  const yuyan = localStorage.getItem(TITLE + 'yuyan') || 'cn'
  return yuyan === 'fr' ? `${DD}-${MM}-${YY}${type === 1 ? ' ' + HH + ':' + mm + ':' + SS : ''}` : `${YY}-${MM}-${DD}${type === 1 ? ' ' + HH + ':' + mm + ':' + SS : ''}`
}

Vue.prototype.format_fr = date => {
  let d = new Date(date)
  const YY = d.getFullYear()
  const MM = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  const DD = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  return `${DD}/${MM}/${YY}`
}

// 返回根据网站语言显示相应内容
Vue.prototype.duoyuyan = (data, key = 'mingcheng') => data[key + '_' + store.state.yuyan] || data[key]
// 返回相应内容对应的key

Vue.prototype['duoyuyan_key'] = (data, key = 'mingcheng') => (data && data[key + '_' + store.state.yuyan] ? key + '_' + store.state.yuyan : key)

// 返回格式化后的多语言对象
Vue.prototype.duoyuyan_object = (data, key = 'mingcheng', html) => {
  const temp = {}
  if (html) {
    data[key] && data[key].replaceAll('<p><br></p>', '') && Object.assign(temp, { [key]: data[key] })
    for (let i = 0, len = store.state.yuyans.length; i < len; i += 1) {
      data[key + '_' + store.state.yuyans[i].jian] &&
        data[key + '_' + store.state.yuyans[i].jian].replaceAll('<p><br></p>', '') &&
        Object.assign(temp, {
          [key + '_' + store.state.yuyans[i].jian]: data[key + '_' + store.state.yuyans[i].jian],
        })
    }
  } else {
    data[key] && Object.assign(temp, { [key]: data[key] })
    for (let i = 0, len = store.state.yuyans.length; i < len; i += 1) {
      data[key + '_' + store.state.yuyans[i].jian] &&
        Object.assign(temp, {
          [key + '_' + store.state.yuyans[i].jian]: data[key + '_' + store.state.yuyans[i].jian],
        })
    }
  }

  return temp
}

// 返回图片完整的url
Vue.prototype.tupian_jia = data => TUPIANBASE + data

// 返回图片集合 去除图片url的域名 只保留文件名
Vue.prototype.tupian_jian = data => {
  if (Array.isArray(data)) return data.map(v => v.substring(TUPIANBASE.length))
  else return data.substring(TUPIANBASE.length)
}

Vue.prototype.quchong = data => {
  const newArr = []
  data.forEach(v => {
    if (!newArr.includes(v)) {
      newArr.push(v)
    }
  })
  return newArr
}
